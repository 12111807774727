
import CustomButton from '@/common/button/CustomButton.vue';
import {defineComponent} from 'vue';
import ValidationStatus from "@/quickcheck/model/ValidationStatus";
import {quickcheckPropertyStore} from "@/quickcheck/model/currentQuickcheckProperty";

export default defineComponent({
  components: {CustomButton},
  setup() {
    const currentQuickcheckPropertyStore = quickcheckPropertyStore()
    return {currentQuickcheckPropertyStore}
  },
  data() {
    return {
      constructionYear: '',
      livingSpace: '',
      buildingType: '',
      numberOfRooms: '',
      energyDemand: '',
      validationResultTypes: ValidationStatus,
      quickcheckPropertyValidity: {
        buildingTypeValidity: ValidationStatus.OK,
        constructionYearValidity: ValidationStatus.OK,
        livingSpaceValidity: ValidationStatus.OK,
        numberOfRoomsValidity: ValidationStatus.OK,
        energyDemandValidity: ValidationStatus.OK,
      },
    }
  },
  methods: {
    setBuildingType(buildingType: string) {
      this.buildingType = buildingType;
    },
    validateInput() {
      this.quickcheckPropertyValidity = this.$ports.quickcheck.validateQuickcheckProperty({
        buildingType: this.buildingType,
        constructionYear: parseInt(this.constructionYear),
        livingSpace: parseInt(this.livingSpace),
        numberOfRooms: parseInt(this.numberOfRooms),
        energyDemand: parseInt(this.energyDemand),
      });
    },
    async submitData() {
      this.validateInput();

      if (this.quickcheckPropertyValidity.buildingTypeValidity === ValidationStatus.MISSING) {
        this.quickcheckPropertyValidity.buildingTypeValidity = ValidationStatus.ERROR;
      }
      if (this.quickcheckPropertyValidity.constructionYearValidity === ValidationStatus.MISSING) {
        this.quickcheckPropertyValidity.constructionYearValidity = ValidationStatus.ERROR;
      }
      if (this.quickcheckPropertyValidity.livingSpaceValidity === ValidationStatus.MISSING) {
        this.quickcheckPropertyValidity.livingSpaceValidity = ValidationStatus.ERROR;
      }
      if (this.quickcheckPropertyValidity.numberOfRoomsValidity === ValidationStatus.MISSING) {
        this.quickcheckPropertyValidity.numberOfRoomsValidity = ValidationStatus.ERROR;
      }
      if (this.quickcheckPropertyValidity.constructionYearValidity !== ValidationStatus.ERROR &&
          this.quickcheckPropertyValidity.livingSpaceValidity !== ValidationStatus.ERROR &&
          this.quickcheckPropertyValidity.numberOfRoomsValidity !== ValidationStatus.ERROR &&
          this.quickcheckPropertyValidity.buildingTypeValidity !== ValidationStatus.ERROR) {

        this.currentQuickcheckPropertyStore.buildingType = this.buildingType;
        this.currentQuickcheckPropertyStore.livingSpace = parseFloat(this.livingSpace);
        this.currentQuickcheckPropertyStore.constructionYear = parseInt(this.constructionYear);
        this.currentQuickcheckPropertyStore.numberOfRooms = parseInt(this.numberOfRooms);
        this.currentQuickcheckPropertyStore.energyDemand = parseFloat(this.energyDemand);

        await this.$router.push({
          name: 'quickcheck-results',
        });
      }
    }
  },
});

